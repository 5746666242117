import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from '../components/Team/Banner'
import SEO from "../components/seo"
import MemberSection from "../components/Team/MemberSection"
  

const Team = () => {
  const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoxMg==") {
          title
        }
      }
    }
  `)

  let page_title = initial_data_query.wpgraphql;
  let title = page_title.page.title;
  

  return (
    <div className="team-main-wrapper">
      
      
    <Layout>
        <SEO title={title}/>
        <div id="teampage">
            <Banner/>
            <MemberSection />
        </div>
    </Layout>
  </div>
  )
}

export default Team;

