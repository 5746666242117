import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';

const MemberSection = () => {
  const initial_data_query = useStaticQuery(graphql`
    query {
      wpgraphql {
        teams {
          nodes {
            acf_Team_Members {
              memberDescription
              memberDesignation
              memberLinkedinUrl
              memberName
              memberBgColor
            }
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  `);

  let initial_state_data = initial_data_query.wpgraphql;

  const [existingData, updateData] = useState(initial_state_data);
  const [loader, setLoader] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // New state for popup visibility
  const [selectedMember, setSelectedMember] = useState(null); // New state to store selected member
  const [selectedMemberImage, setSelectedMemberImage] = useState(null); // New state to store selected member

  let memberData = existingData.teams.nodes;

  useEffect(() => {
    let curr_api = process.env.GATSBY_API_URL;

    fetch(`${curr_api}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
          {   
            teams {
              nodes {
                acf_Team_Members {
                  memberDescription
                  memberDesignation
                  memberLinkedinUrl
                  memberName
                  memberBgColor
                }
                featuredImage {
                  node {
                    sourceUrl
                    altText
                  }
                }
              }
            }
          }
        `,
      }),
    })
      .then(res => res.json())
      .then(data => updateData(data.data))
      .then(() => setLoader(false))
      .catch(error => console.log(`Some Error Occurred! The Error :${error}`));
  }, []);
  useEffect(() => {
    if (showPopup) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  
    return () => {
      document.body.classList.remove('popup-open');
    };
  }, [showPopup]);

  const openPopup = (member, images) => {
    console.log(member, "0000")
    setSelectedMember(member);
    setSelectedMemberImage(images);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };




  return (
    <>
      {loader ? (
        <div id="loading_gif" className="flex_view_xs middle center">
          {/* Loader content */}
        </div>
      ) : (
        <div className="team-member-container-class">
          <div className="container clearfix">
            <div className="team-member-section-wrapper">
              {memberData.map((item, index) => (
                <div key={index} className="team-member-section">

                  <div
                  style={{ background: item.acf_Team_Members.memberBgColor }}
                    className="member-bg"
                    onClick={() => openPopup(item.acf_Team_Members, item.featuredImage)}
                  >
                    
                    <img
                      src={item.featuredImage.node.sourceUrl}
                      alt={item.featuredImage.node.altText}
                    />
                  </div>
                  <h2>{item.acf_Team_Members.memberName}</h2>
                  <span className="member-designation">
                    {item.acf_Team_Members.memberDesignation}
                  </span>
                  <a className="member-linkedin-link" target="_blank" href={item.acf_Team_Members.memberLinkedinUrl}><svg id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" /><path d="m.396 7.977h4.976v16.023h-4.976z" /><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" /></svg></a>
                  <div className="memberDescription">{parse(item.acf_Team_Members.memberDescription)}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showPopup && (
        <div onClick={closePopup} className="popup-bg">
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="popup-content">

              <div className="team-member-section">
                <div className="member-bg" style={{ background: selectedMember.memberBgColor }}>

                  <>
                    {selectedMemberImage.node ? (
                      <>
                        <img
                          src={selectedMemberImage.node.sourceUrl}
                          alt={selectedMemberImage.node.altText}
                        />
                      </>
                    ) : (
                      <p>No node property in featuredImage</p>
                    )}
                  </>
                </div>


                <h2>{selectedMember.memberName}</h2>
                <span className="member-designation">{selectedMember.memberDesignation}</span>
                <a
                  className="member-linkedin-link"
                  target="_blank"
                  href={selectedMember.memberLinkedinUrl}
                >
                  <svg id="Bold" fill="white" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                    <path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" />
                    <path d="m.396 7.977h4.976v16.023h-4.976z" />
                    <path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" />
                  </svg>
                </a>
              </div>
              <div className="memberDescription">
                {parse(selectedMember.memberDescription)}
              </div>
            </div>
            <button onClick={closePopup}>
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.3969 3.46097C17.7485 3.10934 17.9461 2.63243 17.9461 2.13515C17.9461 1.63787 17.7485 1.16095 17.3969 0.809321C17.0453 0.457691 16.5683 0.260147 16.0711 0.260147C15.5738 0.260147 15.0969 0.457691 14.7452 0.809321L9 6.55456L3.25476 0.809321C2.90313 0.457691 2.42621 0.260147 1.92893 0.260147C1.43165 0.260147 0.954738 0.457691 0.603107 0.809321C0.251476 1.16095 0.0539323 1.63787 0.0539323 2.13515C0.053933 2.63243 0.251477 3.10934 0.603107 3.46097L6.34835 9.20621L0.603107 14.9515C0.251476 15.3031 0.0539324 15.78 0.053932 16.2773C0.0539324 16.7746 0.251476 17.2515 0.603107 17.6031C0.954738 17.9547 1.43165 18.1523 1.92893 18.1523C2.42621 18.1523 2.90313 17.9547 3.25476 17.6031L9 11.8579L14.7452 17.6031C15.0969 17.9547 15.5738 18.1523 16.0711 18.1523C16.5683 18.1523 17.0453 17.9547 17.3969 17.6031C17.7485 17.2515 17.9461 16.7746 17.9461 16.2773C17.9461 15.78 17.7485 15.3031 17.3969 14.9515L11.6517 9.20621L17.3969 3.46097Z" fill="#7F7F7F" />
              </svg>
            </button>
          </div>
        </div>
      )}

    </>
  );
};

export default MemberSection;
